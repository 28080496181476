/*
 *  Default CSS For Footer Menu.
 *
 */

.menu-no-dropdowns, .footer .menu-no-dropdowns {

  ul.nav {
    // font-family:$font-family-sans-=serif;

    > li {
      display: block;
      padding-right:1em;

      > a {
        color:#ffffff;
        padding:4px 5px;
        font-size:1.2em;
        padding-left:5px;
        margin:0 -5px;
        margin-right:5px;
        border-bottom:1px solid transparent;

        &:hover, &:focus {
            border-bottom:1px solid #ffffff;
        }

        b.caret, span.caret {display: none;}
          span.fa.fa-caret-down {
            display:none !important;
          }
      }

      //Dropdown styles
      &.open > a {

        background:transparent;
        border-bottom:1px solid #ffffff;

        &:hover, &:focus {
          background:transparent;
          border-bottom:1px solid #ffffff;
        }

      }

      //Show dropdowns
      ul.dropdown-menu {
        display:inline-block !important;
        position: relative;
        background:transparent;
        @include box-shadow(none);
        padding:0;
        border:none;
        float:none;
        min-width: 120px;
        top:0;

        li {
          margin-right:0em;
          a {
            color:#ffffff;
            padding:5px 0;
            font-size:1em;
            font-weight:300;
            color:darken(#ffffff,25%);
            margin-right:0;
            background:transparent;

            &:hover, &:active {
              background:transparent;
              text-decoration:underline;
            }
          }
        }
      }



    }
  }

  //Top level nav colors
  .nav > li > a:hover, .nav > li > a:focus {
    text-decoration: none;
    background-color: transparent;
    @include border-radius(0);
  }


}

@media(min-width:$screen-sm) {
  .menu-no-dropdowns, .footer .menu-no-dropdowns {

    ul.nav {
      // font-family:$font-family-sans-=serif;

      > li {
        display: inline-block;
        ul.dropdown-menu {
          float:left;
        }
      }

    }
  }
}
