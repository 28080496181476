
/* line 4, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_layout.scss */
.container .container,
.container .container-fluid {
  width: auto;
  margin-left: -15px;
  margin-right: -15px;
}

/* line 12, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_layout.scss */
#footer {
  margin: 36px 0;
}

/* line 6, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
#logo {
  display: block;
  float: left;
  margin-right: 15px;
  height: 100%;
  width: auto;
}

/* line 16, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
#site-name {
  float: left;
}

/* line 22, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
.page-header {
  margin: 0 0 18px;
}
/* line 24, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
.page-header h1 {
  margin: 0;
}

/* line 29, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
.page-title-hidden .page-header {
  display: none;
}

/* line 38, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
.breadcrumb a i {
  display: none;
}

/* line 46, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
.tabs, .nav-tabs {
  margin: 0 0 18px;
}

/* line 52, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
.action-links {
  list-style: none outside none;
  margin: 0 0 18px;
  padding: 0;
}
/* line 14, ../../vendor/cache/ruby/2.0.0/gems/bootstrap-sass-3.3.6/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.action-links:before, .action-links:after {
  content: " ";
  display: table;
}
/* line 19, ../../vendor/cache/ruby/2.0.0/gems/bootstrap-sass-3.3.6/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
.action-links:after {
  clear: both;
}
/* line 57, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
.action-links > li {
  float: left;
  margin: 0 10px 0 0;
}

/* line 65, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
div.vertical-tabs {
  border: none;
  border: 1px solid #DDD;
  outline: none;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
/* line 70, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
div.vertical-tabs ul.vertical-tabs-list {
  border: none;
  margin-top: 20px;
  margin-bottom: 20px;
  outline: none;
}
/* line 75, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
div.vertical-tabs ul.vertical-tabs-list li {
  border: none;
  background: none;
  outline: none !important;
  -moz-border-radius: 4px 0 0 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px 0 0 4px;
  border: 1px solid;
  border-color: #fff #ddd #fff #fff;
}
/* line 82, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
div.vertical-tabs ul.vertical-tabs-list li.selected {
  border-color: #ddd #fff #ddd #ddd;
}
/* line 85, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
div.vertical-tabs ul.vertical-tabs-list li a {
  outline: none !important;
  -moz-border-radius: 4px 0 0 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px 0 0 4px;
}
/* line 88, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
div.vertical-tabs ul.vertical-tabs-list li a:hover {
  color: #fff;
  background: #008cba;
  text-decoration: none;
}
/* line 92, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
div.vertical-tabs ul.vertical-tabs-list li a:hover strong {
  text-decoration: none;
  color: #fff;
}
/* line 100, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
div.vertical-tabs .vertical-tabs-panes {
  padding: 20px 10px;
}
/* line 103, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
div.vertical-tabs .form-type-textfield input {
  width: 90%;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

/* line 111, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
table {
  width: 100%;
  margin-bottom: 1.42857143;
}
/* line 115, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
table th,
table td {
  padding: 8px;
  line-height: 1.42857143;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
/* line 122, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
table th.checkbox,
table td.checkbox {
  text-align: center;
  display: table-cell;
  min-height: 0;
  margin-bottom: 0;
  padding-left: 0;
}
/* line 128, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
table th.checkbox input,
table td.checkbox input {
  float: none !important;
  margin-left: 0 !important;
}
/* line 132, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
table th.checkbox div.checkbox,
table td.checkbox div.checkbox {
  text-align: center;
  min-height: 0;
  margin-bottom: 0;
  padding-left: 0;
}
/* line 140, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
table th {
  font-weight: bold;
}
/* line 144, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
table thead th {
  vertical-align: bottom;
}
/* line 148, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
table caption + thead tr:first-child th,
table caption + thead tr:first-child td,
table colgroup + thead tr:first-child th,
table colgroup + thead tr:first-child td,
table thead:first-child tr:first-child th,
table thead:first-child tr:first-child td {
  border-top: 0;
}
/* line 157, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
table tbody + tbody {
  border-top: 2px solid #ddd;
}

/* line 164, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
ul.links {
  padding: 0;
  list-style: none outside none;
}
/* line 167, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
ul.links.inline {
  margin: 0;
}
/* line 169, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
ul.links.inline li {
  margin: 0 10px 0 0;
  padding: 0;
}

/* line 179, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
.carousel .carousel-control {
  z-index: 100;
}
/* line 182, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
.carousel .carousel-indicators li {
  float: none;
  margin-left: 3px;
  margin-right: 3px;
}
/* line 186, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
.carousel .carousel-indicators li a {
  display: none;
}
/* line 191, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
.carousel .carousel-caption h1, .carousel .carousel-caption h2, .carousel .carousel-caption h3, .carousel .carousel-caption h4, .carousel .carousel-caption h5, .carousel .carousel-caption h6 {
  color: white;
}

/* line 200, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
.item-list ul.pagination {
  margin: 20px 0;
}
/* line 202, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
.item-list ul.pagination > li {
  margin: 0;
  display: inline-block;
  padding: 0;
  position: relative;
}
/* line 207, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
.item-list ul.pagination > li > a, .item-list ul.pagination > li > span {
  padding: 6px 12px;
  -moz-border-radius: inherit;
  -webkit-border-radius: inherit;
  border-radius: inherit;
}
/* line 210, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
.item-list ul.pagination > li > a.progress-disabled, .item-list ul.pagination > li > span.progress-disabled {
  float: left;
}
/* line 214, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
.item-list ul.pagination > li .ajax-progress {
  float: left;
  padding: 6px 5px 6px 10px;
}

/* line 225, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
.ctools-dropdown-container hr {
  margin: 0;
}

/* line 230, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
ul.nav,
ul.navl li,
ul.dropdown-menu,
ul.dropdown-menu li {
  list-style: none outside none !important;
}

/* line 243, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
.calendar-calendar table.mini td {
  border: 1px solid #eeeeee !important;
  padding: 5px;
}

/* line 251, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
.view-header .date-nav-wrapper h3 {
  margin-top: 5px !important;
  margin-bottom: 0;
}

/* line 260, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_structure.scss */
.ui-tabs .ui-tabs-nav {
  padding: 0 5px 5px 5px;
}

/* line 3, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_modal.scss */
body.ctools-modal-open {
  overflow: hidden;
}

/* line 7, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_modal.scss */
#modalContent .modal-content {
  width: 100%;
  overflow: hidden;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
/* line 12, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_modal.scss */
#modalContent #modal-content {
  overflow: auto;
  width: 100% !important;
  background: white;
}
/* line 17, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_modal.scss */
#modalContent .panels-add-content-modal {
  background: none;
  padding: 0;
  height: 100%;
  margin: -20px -20px 0;
  width: 100%;
  position: absolute;
}

/* line 27, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_modal.scss */
#modalContent .panels-section-columns,
#modalContent .panels-categories-description {
  border: none;
}
/* line 31, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_modal.scss */
#modalContent .panels-section-column-categories {
  margin-left: 0;
  border-right: 1px solid #ccc;
  height: 100%;
  background: white;
  overflow: auto;
  padding-bottom: 20px;
}
/* line 38, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_modal.scss */
#modalContent .panels-section-column-categories .btn {
  border: 1px solid #999999 !important;
  line-height: inherit;
  margin: 0;
  text-align: left;
}
/* line 45, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_modal.scss */
#modalContent .panels-categories-box {
  border: 0;
}
/* line 47, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_modal.scss */
#modalContent .panels-categories-box a.btn {
  padding: 5px 10px;
}
/* line 50, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_modal.scss */
#modalContent .panels-categories-box a.active {
  background: #eeeeee;
  -webkit-box-shadow: none;
  box-shadow: none;
}
/* line 56, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_modal.scss */
#modalContent .panels-root-content-box a.btn {
  padding: 5px 10px;
}
/* line 60, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_modal.scss */
#modalContent .panels-categories-description {
  padding: 20px;
}
/* line 63, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_modal.scss */
#modalContent .panels-section-columns {
  padding-bottom: 20px;
}

/* line 67, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_modal.scss */
#modal-content fieldset.widget-preview {
  margin-top: 18px;
  margin-bottom: 0;
  position: static;
  border: 1px solid #ddd;
}
/* line 72, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_modal.scss */
#modal-content fieldset.widget-preview .panel-heading {
  position: relative;
}
/* line 74, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_modal.scss */
#modal-content fieldset.widget-preview .panel-heading .btn {
  position: absolute;
  right: 10px;
  top: 5px;
}

/* line 82, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_modal.scss */
#modal-content .ctools-auto-submit-full-form fieldset {
  width: 48%;
  display: block;
}
/* line 85, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_modal.scss */
#modal-content .ctools-auto-submit-full-form fieldset fieldset {
  width: 100%;
}
/* line 88, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_modal.scss */
#modal-content .ctools-auto-submit-full-form fieldset.widget-preview-single {
  float: right;
  margin-left: 1em;
  margin-top: 0;
  width: 48%;
  max-width: 48% !important;
}
/* line 96, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_modal.scss */
#modal-content .ctools-auto-submit-full-form .form-actions {
  float: right;
  clear: right;
  width: 48%;
  max-width: 48%;
  text-align: right;
}

/* line 107, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_modal.scss */
#modal-content .form-item-view-settings {
  display: block;
}
/* line 110, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_modal.scss */
#modal-content .form-item-exposed-use-pager {
  display: block;
}
/* line 113, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_modal.scss */
#modal-content #panopoly-form-widget-preview {
  width: 50%;
  float: right;
}
/* line 116, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_modal.scss */
#modal-content #panopoly-form-widget-preview .container {
  width: auto;
}

/* line 3, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_navbar.scss */
.drupal-navbar .navbar-bar,
.drupal-navbar .navbar-tray-vertical > .navbar-lining:before,
.drupal-navbar .navbar-tray-horizontal {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* line 8, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_navbar.scss */
.drupal-navbar .navbar-tray-vertical,
.drupal-navbar .navbar-tray-vertical > .navbar-lining:before {
  bottom: 0;
  width: 240px !important;
}

@media only screen and (min-width: 38.125em) {
  /* line 14, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_navbar.scss */
  body.navbar-tray-open.navbar-vertical {
    margin-left: 240px !important;
  }
}
/* line 22, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_navbar.scss */
a#navbar-link-admin-panopoly:before {
  background-image: url("../images/icon/icon-panopoly.png");
}
/* line 25, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_navbar.scss */
a#navbar-link-admin-panopoly.active:before {
  background-image: url("../images/icon/icon-panopoly-active.png");
}

/* line 30, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_navbar.scss */
a#navbar-link-admin-apps:before {
  background-image: url("../images/icon/icon-apps.png");
}
/* line 33, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_navbar.scss */
a#navbar-link-admin-apps.active:before {
  background-image: url("../images/icon/icon-apps-active.png");
}

/* line 6, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_menu.scss */
ul.menu > li > ul.menu {
  margin-left: 1em;
  /* LTR */
}

/* line 15, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_menu.scss */
ul.nav > li.dropdown:after {
  width: 100%;
  height: 2px;
  background: none;
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  content: '';
}

/* line 32, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_menu.scss */
.nav-expanded .caret {
  display: none;
}
/* line 36, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_menu.scss */
.nav-expanded > li > a {
  font-size: 1.2em;
  text-align: left;
  font-weight: 700;
  color: #222222;
}
/* line 41, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_menu.scss */
.nav-expanded > li > a:hover, .nav-expanded > li > a:focus, .nav-expanded > li > a.active, .nav-expanded > li > a.active-trail {
  color: #00526e;
  background: none;
}
/* line 46, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_menu.scss */
.nav-expanded > li ul.dropdown-menu {
  background: none;
  display: block;
  border: 0;
  padding: 0;
  position: static;
  float: none;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
/* line 56, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_menu.scss */
.nav-expanded > li ul.dropdown-menu li a {
  padding: 3px 15px;
  color: #222222;
}
/* line 59, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_menu.scss */
.nav-expanded > li ul.dropdown-menu li a:hover, .nav-expanded > li ul.dropdown-menu li a:focus, .nav-expanded > li ul.dropdown-menu li a.active, .nav-expanded > li ul.dropdown-menu li a.active-trail {
  color: #00526e;
  background: none;
}
/* line 65, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_menu.scss */
.nav-expanded > li ul.dropdown-menu ul {
  display: none !important;
}
@media (max-width: 767px) {
  /* line 35, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_menu.scss */
  .nav-expanded > li {
    float: none;
    margin-bottom: 18px;
  }
}
/* line 74, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_menu.scss */
.nav-expanded > li.open > a {
  color: #222222;
  background: none;
}
/* line 77, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_menu.scss */
.nav-expanded > li.open > a:hover, .nav-expanded > li.open > a:focus, .nav-expanded > li.open > a.active, .nav-expanded > li.open > a.active-trail {
  color: #00526e;
  background: none;
}

/* line 7, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_panel.scss */
.panel-display > .row {
  margin-bottom: 20px;
}

/* line 11, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_panel.scss */
.panel-flexible .panel-separator {
  display: none;
}

/* line 19, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_panel.scss */
.panels-layouts-checkboxes input[type="radio"] {
  position: static;
  margin-left: 0px;
  margin-bottom: 20px;
}
/* line 24, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_panel.scss */
.panels-layouts-checkboxes label {
  margin-left: 0px;
}
/* line 27, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_panel.scss */
.panels-layouts-checkboxes .form-type-radio {
  margin: 0 !important;
}

/* line 34, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_panel.scss */
div#panels-ipe-control-container {
  width: auto;
  left: 0;
  right: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
/* line 39, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_panel.scss */
div#panels-ipe-control-container .panels-ipe-button-container {
  text-align: center;
}
/* line 41, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_panel.scss */
div#panels-ipe-control-container .panels-ipe-button-container .panels-ipe-pseudobutton-container {
  margin: 0 5px;
}
/* line 43, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_panel.scss */
div#panels-ipe-control-container .panels-ipe-button-container .panels-ipe-pseudobutton-container a {
  padding: 0px 10px 0px 34px;
}
/* line 45, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_panel.scss */
div#panels-ipe-control-container .panels-ipe-button-container .panels-ipe-pseudobutton-container a:hover {
  text-decoration: none;
}
/* line 53, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_panel.scss */
div#panels-ipe-control-container .panels-ipe-form-container form > div {
  text-align: center;
}
/* line 56, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_panel.scss */
div#panels-ipe-control-container .panels-ipe-form-container form .form-submit {
  margin: 0 5px;
  padding: 0px 10px 0px 34px;
}
/* line 59, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_panel.scss */
div#panels-ipe-control-container .panels-ipe-form-container form .form-submit:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* line 67, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_panel.scss */
ul.panels-ipe-linkbar {
  list-style: none outside none;
}
/* line 69, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_panel.scss */
ul.panels-ipe-linkbar > li {
  display: inline-block;
  margin: 0 0 0 5px;
}
/* line 72, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_panel.scss */
ul.panels-ipe-linkbar > li a {
  margin: 0 !important;
}

/* line 78, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_panel.scss */
body.panels-ipe {
  margin-bottom: 55px !important;
}

/* line 14, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_view.scss */
.views-exposed-form .views-submit-button,
.views-exposed-form .views-reset-button {
  margin-top: 3px;
}

/* line 6, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
.form-item {
  margin-top: 0;
}

/* line 12, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
form .form-managed-file input {
  display: inline-block;
  margin: 0 10px 0 0;
}
/* line 17, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
form .container-inline-date .date-padding {
  padding: 0;
  float: none;
}
/* line 22, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
form .form-actions .btn {
  margin-right: 10px;
}
/* line 27, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
form .field-type-datetime select {
  min-width: 80px;
}
/* line 31, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
form .format-toggle {
  float: none;
}

/* line 39, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
form .field-multiple-table thead th {
  padding: 10px !important;
}
/* line 41, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
form .field-multiple-table thead th label {
  margin: 0;
  font-weight: bold;
}
/* line 48, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
form .description {
  margin: 2px 0;
  color: #6f6f6f;
}

/* line 55, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
.media-widget > a {
  margin: 0 10px 0 0;
  display: inline-block;
}

/* line 62, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
fieldset legend {
  float: left;
  line-height: 1em;
  margin: 0;
}
/* line 67, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
fieldset .panel-body {
  clear: both;
}

/* line 73, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
html.js input.form-autocomplete {
  background: url('../images/throbber.gif?1456786792') no-repeat right 8px #fff !important;
}
/* line 76, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
html.js input.throbbing {
  background-position: right -122px !important;
}
/* line 79, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
html.js fieldset.collapsed {
  height: auto;
  border-left-width: 1px;
  border-right-width: 1px;
}

/* line 86, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
.views-exposed-form .views-exposed-widget .form-submit {
  margin-top: 1.8em;
}

@media (max-width: 767px) {
  /* line 92, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
  form .btn {
    width: 100%;
    display: block;
    clear: both;
    margin: 0 0 5px;
  }
  /* line 98, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
  form input.form-text,
  form textarea,
  form select {
    width: 100%;
  }
  /* line 103, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
  form .confirm-parent,
  form .password-parent,
  form .password-suggestions {
    width: auto !important;
  }
  /* line 109, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
  form .password-parent div.password-strength {
    width: 150px;
    float: right;
    margin-top: 0;
    font-size: 10px;
    margin-bottom: 5px;
    margin-top: -5px;
  }
  /* line 119, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
  form .confirm-parent div.password-confirm {
    width: 110px;
    float: right;
    margin-top: 0;
    font-size: 10px;
    margin-bottom: 5px;
    margin-top: -5px;
  }
}
/* line 134, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
#node-edit #edit-title {
  font-size: 1.6em;
  line-height: 1.6em;
  height: 45px;
  width: 90%;
}
/* line 14, ../../vendor/cache/ruby/2.0.0/gems/bootstrap-sass-3.3.6/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
#node-edit .form-item-path-alias:before, #node-edit .form-item-path-alias:after {
  content: " ";
  display: table;
}
/* line 19, ../../vendor/cache/ruby/2.0.0/gems/bootstrap-sass-3.3.6/assets/stylesheets/bootstrap/mixins/_clearfix.scss */
#node-edit .form-item-path-alias:after {
  clear: both;
}
/* line 142, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
#node-edit .form-item-path-alias label, #node-edit .form-item-path-alias input {
  display: inline;
}
/* line 147, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
#node-edit .burr-flipped-sidebar .panel-pane {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  padding: 10px;
}
/* line 20, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_mixin.scss */
#node-edit .burr-flipped-sidebar .panel-pane blockquote {
  border-color: #ddd;
  border-color: rgba(0, 0, 0, 0.15);
}
/* line 150, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
#node-edit .burr-flipped-sidebar .panel-pane .pane-title {
  margin-top: 0;
}
/* line 154, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
#node-edit .burr-flipped-sidebar .pane-node-form-buttons {
  background: none;
  padding: inherit;
}
/* line 157, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
#node-edit .burr-flipped-sidebar .pane-node-form-buttons .form-actions {
  margin: 0;
  border-top: 0;
}
/* line 163, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
#node-edit .pane-node-form-buttons {
  clear: both;
}

/* line 168, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
.filter-wrapper {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  margin-top: 10px;
}
/* line 20, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_mixin.scss */
.filter-wrapper blockquote {
  border-color: #ddd;
  border-color: rgba(0, 0, 0, 0.15);
}
/* line 171, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
.filter-wrapper .form-item {
  margin: 0 0 10px;
  padding: 0;
}
/* line 175, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
.filter-wrapper .filter-guidelines {
  padding: 0;
}
/* line 177, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
.filter-wrapper .filter-guidelines .tips {
  margin-left: 0;
}

/* line 188, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
.page-admin form table select,
.page-admin form table input.form-text {
  width: 150px;
}

/* line 199, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
#features-export-form .features-export-parent {
  margin: 0 0 20px;
}
/* line 201, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
#features-export-form .features-export-parent fieldset.collapsed {
  min-height: 43px !important;
  background: none;
}
/* line 205, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
#features-export-form .features-export-parent .features-export-list {
  padding: 10px;
}
/* line 207, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
#features-export-form .features-export-parent .features-export-list .form-item {
  float: none !important;
}

/* line 218, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
#panels-panel-context-edit-move .container,
#panels-panel-context-edit-content .container {
  width: auto;
  padding: 0;
}
/* line 221, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
#panels-panel-context-edit-move .container .row,
#panels-panel-context-edit-content .container .row {
  margin-bottom: 20px;
}

/* line 230, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
.navbar-form .form-wrapper {
  padding: 0;
}
/* line 233, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
.navbar-form .form-item-keys,
.navbar-form .form-item {
  margin-top: 0 !important;
}
/* line 237, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
.navbar-form .form-submit {
  display: none;
}

/* line 247, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
.pane-search-box form .form-wrapper .form-group {
  display: block;
  margin-bottom: 10px;
}

/* line 257, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_form.scss */
.tabledrag-handle .handle {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

/* line 6, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_node.scss */
.field-collection-container {
  border: 0;
}
/* line 8, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_node.scss */
.field-collection-container .field-collection-view {
  margin: 0;
  padding: 0;
  border: none;
}

/* line 20, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_node.scss */
.node-panopoly-page.node-teaser .panel-pane {
  margin: 0 0 10px 260px;
}
/* line 23, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_node.scss */
.node-panopoly-page.node-teaser .pane-node-field-featured-image {
  float: left;
  margin-left: 0;
}
@media (max-width: 767px) {
  /* line 29, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_node.scss */
  .node-panopoly-page .panel-pane {
    float: none !important;
    margin: 0 0 10px !important;
  }
}

/* line 41, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_node.scss */
.node-panopoly-news-article.node-teaser .panel-pane {
  margin: 0 0 10px 260px;
}
/* line 44, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_node.scss */
.node-panopoly-news-article.node-teaser .pane-node-field-featured-image {
  float: left;
  margin-left: 0;
}
@media (max-width: 767px) {
  /* line 50, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_node.scss */
  .node-panopoly-news-article .panel-pane {
    float: none !important;
    margin: 0 0 10px !important;
  }
}

/* line 3, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_comment.scss */
.comment {
  margin-top: 15px;
}
/* line 5, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_comment.scss */
.comment .user-picture {
  float: left;
  margin-right: 10px;
}
/* line 8, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_comment.scss */
.comment .user-picture a {
  display: block;
}
/* line 12, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_comment.scss */
.comment .submitted {
  font-size: 0.90em;
}
/* line 14, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_comment.scss */
.comment .submitted .permalink {
  margin-left: 5px;
}
/* line 17, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_comment.scss */
.comment .submitted .new {
  color: #F04124;
}
/* line 21, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_comment.scss */
.comment .content {
  margin: 10px 0;
}
/* line 24, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_comment.scss */
.comment .links {
  font-size: 0.80em;
}

/* line 3, ../../vendor/cache/ruby/2.0.0/bundler/gems/compass_radix-8cbb4305d33d/stylesheets/compass_radix/_admin.scss */
.views-display-setting .views-admin-label {
  display: inline-block;
  float: left;
  /* LTR */
  margin-right: 3pt;
  /* LTR */
}
