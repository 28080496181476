/*
 *  Default CSS For Parcel CTA Bar feature.
 *
 */


.cta-bar {
  background:#f9f9f9;

  > .container-fluid > .row {
    padding:30px;
  }

  &.jumbotron, &.panel, &.well {
    > .container-fluid > .row {
      padding:0;
    }
  }
}

.field-cta-name {
  font-size:2em;
  margin-bottom:10px;
}

.field-cta-description {
  font-weight:300;
}

.field-cta-link {

  a.btn {
    position:relative;
    padding-right:30px;

    &:before {
        position:absolute;
        font-family: FontAwesome;
        font-size:1.1em;
        right:10px;
        content: "\f105";
    }
  }

}


.slick-prev::before,
.slick-next::before {
  color: rgba(#ffffff,0.75);
  font-size: 36px;
  font-size: 2.25rem;
}

.slick-prev:hover::before,
.slick-next:hover::before {
  color: #ffffff;
}
