// Styles for Menus and Navs
// --------------------------------------------------

.navbar {
  border-radius:0;
}

.panel-pane.pane-site-navbar {
  margin-bottom:0;
}

@media(min-width:$screen-sm) {


  .navbar-nav {
    float:right !important;
  }

}

.navbar-toggle {

  margin-top:10px;

  .navbar-toggle-text {
    display: none;
  }


}

.navbar-default {
  position: relative;
  z-index: $zindex-navbar;
  .navbar-nav {
    margin-top:10px;
    > li a {
      // text-transform: uppercase;
    }
    > li {
      > ul.dropdown-menu {
          background:darken($navbar-default-bg, 70%);
        > li a {
          background:darken($navbar-default-bg, 70%);
          color:lighten($navbar-default-color,60%);

          &:hover{
            background:$navbar-default-bg;
            color:darken($navbar-default-bg, 80%);
          }
        }
      }
    }
  }
}

.social-media-header{
  margin-top: 15px;
  img{
    max-width: 30px;
  }
}

@media(max-width:$screen-sm) {
.social-media-header{
  margin-top: 0px !important;
  margin-bottom: 15px; 
  margin-right: 10px;
  }
}