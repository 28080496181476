.node-type-team-member{
    .team-member-page-header{
        background: #f9f9f9 !important;
        padding-bottom: 0px !important;
        padding-left: 40px !important;
        padding-right: 40px !important;
        p.page-header{
            margin-top: 0;
            text-transform: uppercase;
            border-bottom: none;
        }
    }
    .field-photo-team-member{
        img{
            border-radius: 50%;
            width: 80%;
            margin-top: -10px;
            border:3px solid #1abc9c;
        }
    }
    .pane-team-member-node-header-team-member-node-header{
        //padding-left: 50px;
    }
    .view-team-member-node-header{
        h1{
            color: #000 !important;
            font-size: 2.4em;
            margin-bottom: 0px;
        }
        .field-position-team-member{
            font-weight: 500;
            font-size: 2em;
        }
    }
    .back-to-pilots{
        background: #fff !important;
        padding-left: 25px;
    }
    .pane-node-body{
        padding-top: 25px !important;
    }
}

.view-display-id-panel_pane_all_team_members,
.view-display-id-team_member_list{
    .view-content{
        a {
            display:block;
            background: #fff;
            transition: all .2s ease-in-out;

            figure.field-featured-media {
                overflow:hidden;
                img {
                    transition: all .2s ease-in-out;
                }
            }

            &:hover {
                transform: scale(1.05);
                box-shadow: 1px 1px 8px #d9d9d9;
                figure.field-featured-media {

                    img {
                        transform: scale(1.05);
                    }
                }
            }
        }
        .home-news-readmore,
        .team_member-name{
                color: #1abc9c;
            }
        a:hover,a:focus{
            text-decoration: none;
            img{
                border:3px solid #1abc9c;
            }
            .team_member-name{
                color: #1abc9c;
            }
        }
    }
    .home-profiles-header{
        height: 95px;
        margin-top: 95px;
        text-align: center;
        img{
            border-radius: 50%;
            border:3px solid #5d5d5d;
            margin-top: -95px;
        }
        .field-photo-team-member{
            border-top: 1px solid #ccc;
            border-left: 1px solid #ccc;
            border-right: 1px solid #ccc;
        }

    }
    .home-profile-content{
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        text-align: center;
        padding: 25px 25px;
        min-height: 230px;
        .team-member-name{
            color: #000;
            font-weight: 600;
            font-size: 1.3em;
        }
        .field-position-team-member{
            color: #bbb;
            font-size: 1.1em;
        }
        .field-body{
            color: #bbb;
        }
        .home-news-readmore{
            font-weight: 600;
        }
    }
    .views-col{
        padding-left: 15px;
        padding-right: 15px;
    }
    .view-footer{
        text-align: center;
        padding-top:30px;
        a.btn{
            font-size: 1em;
        }
    }
    .views-row{
        margin-bottom: 35px;
    }
}
.view-homepage-team-members{
    .field-photo-team-member img{
        max-width: 190px;
    }
    .field-position-team-member{
        font-size: 0.9em;
    }
    .team_member-name{
        font-size: 1.6em;
        font-weight: 500;
    }
    .field-title-team-member,
    .field-position-team-member{
        color: #000 !important;
    }
}
.view-id-team_member_node_header{
    .field-photo-team-member{
        text-align: center;
        margin-bottom: 35px;
    }
    .field-title-team-member,
    .field-position-team-member{
      font-size: 1.3em !important;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .team_member-title{
        color: #000;
    }

    
    a.btn-sm{
        margin-top: 35px
    }
}

@media(max-width:$screen-md) {
    .view-homepage-team-members{
        .views-col{
            padding-bottom: 25px;
        }
    }
    .view-team-member-node-header{
        h1{
            font-size: 1.4em !important;
        }
    }
    .node-type-team-member .view-team-member-node-header .field-position-team-member{
        font-size: 1em !important;
    }
}
@media(min-width:$screen-md) {
    .back-to-pilots{
        padding-left: 45px !important;
    }
}
p.page-header {
    font-family: "Source Sans Pro","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 700;
    line-height: 1.1;
    color: #a2190d;
    font-size: 2em;
}

@media (min-width: 768px){
    p.page-header{
        margin-top: 0;
        margin-bottom: 10px;

    }
}
@media (min-width: 480px){
    p.page-header{
        margin-top: 10px;
        margin-bottom: 6px;
    }
}
@media (min-width: 480px){
    p.page-header{
        margin-top: 10px;
        margin-bottom: 6px;
    }
}


@media (min-width: 480px){
    p.page-header {
        font-size: 2.5em;
    }
}
@media (min-width: 768px){
    p.page-header {
        font-size: 3em;
    }
}
@media (min-width: 992px){
    p.page-header {
        font-size: 4em;
    }
}
@media (min-width: 1200px){
    p.page-header {
        font-size: 4em;
    }
}


