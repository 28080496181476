
//Add disabled styling to regions
div.ipe-disabled {

  background:rgba(red,0.1);
  border:2px dashed rgba(red,0.5);
  opacity:0.2;
}

.panels-ipe-sort-container.ui-sortable-disabled {
  opacity:0.2;
}

div.panels-ipe-newblock {
  box-shadow:none;
}

.panels-ipe-editing {

  .panels-ipe-portlet-wrapper {
    position: relative;
  }

  //Make draggable handle entire div
  .panels-ipe-handlebar-wrapper {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0);
  }

  .panels-ipe-editing div.panels-ipe-portlet-wrapper {
    opacity: 1;
    position: relative;
    z-index: 0;
    width: 100%;
  }

  .panels-ipe-dragbar {
    border: none;
    position: relative;
    height: 100%;
    background: transparent;
    z-index:1;
  }

  div.panels-ipe-draghandle {
    background:rgba(255,255,255,0.0);

    &:hover {
      background:rgba(255,255,255,0.5);

    span.panels-ipe-draghandle-icon-inner {
      color:#333333;
    }
    }
  }

  div.panels-ipe-draghandle span.panels-ipe-draghandle-icon {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background: transparent;


  }

  span.panels-ipe-draghandle-icon-inner {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index:5;
    background:none;

    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 2.5em;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; 
      color:#666666;

    // @include text-shadow;
    &:before {
      content: "\f047";
    }


  }


  div.panels-ipe-handlebar-wrapper ul {
    position:absolute;
    left:10px;
    top:10px;
    z-index:15;
  }

}



