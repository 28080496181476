

.footer {
  padding-top:1em;

  p {
    color:#ffffff;
  }

  .pane-title {
    border-bottom:1px solid rgba(255,255,255,0.15);
    padding-bottom:1em;
    display:block;
  }
}
