/*
 *  Contact Info Pane
 *  Added through parcel_plugins module.
 */


.pane-contact-info {
  address {
    .company-name {
      margin-bottom:5px;
    }
    i {
      float:left;
      margin-top:3px;
    }

    p {
      display: block;
      padding-left: 20px;
    }
  }
}
