#panels-ipe-edit-control-form #panelizer-save-default {
  display: inline-block;
}

.ui-front {
  z-index:1001;
}

/*
 * ADMIN HACKS
 */

//Hide IPE pane edit buttons for immovable panes on node pages
body.role-editor.node-type-panopoly-page,
body.role-editor.node-type-gallery,
body.role-editor.node-type-event,
body.role-editor.node-type-faq,
body.role-editor.node-type-panopoly-news-article
{
  .panels-ipe-nodrag,
  #panels-ipe-paneid-new-a536bbb5-232a-43ea-970f-c3be8b8db882, //Galleries list
    {
   .panels-ipe-linkbar {
    display: none;
   }
  }
}



//Label that appears over draggable elements
.panels-ipe-dragbar-admin-title {
  @extend .label;

  background:green;
  color:white;
  font-family:sans-serif;
  font-weight:normal;
  position: absolute;
  bottom: 5px;
  right: 5px;

}


//Absolutely position buttons in bottom right of module
body.logged-in {
  #fieldable-panels-panes-fieldable-panels-pane-content-type-edit-form {
    #edit-buttons {
      position: absolute;
      bottom: 10px;
      right: 10px;
      z-index:1000;
    }
  }
}

#admin-menu, #admin-menu .dropdown {
    z-index: 1001;
}
