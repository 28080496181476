// Styles for Views
// --------------------------------------------------

// Events
// --------------------------------------------------
.view-events {
  .views-row {
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
}
.calendar-calendar {
  width: auto !important;
  float: none !important;
  padding: 0 !important;
}