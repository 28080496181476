// Styles for individual pages
// --------------------------------------------------


address i {
  margin-right: 10px;
}

footer, .footer {
  background:darken($brand-primary,35%);
  color:#ffffff;

  .footer-inner-wrap {
    border-top:10px solid darken($brand-primary,20%);
    padding:2em 0;
  }

  a:link {
    color:#ffffff;
  }

  h1,h2,h3,h4,h5,h6 {
    color:#ffffff;
  }

}


body img {
    max-width: 100%;
    height: auto;
}
