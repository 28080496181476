html,
body { height: 100%; }

body {
  display: table;
  width: 100%;
}

footer, .footer {
  display: table-row;
  height: 1px;
}

div.page {
  display: table;
  height: 100%;
  table-layout: fixed;
  width: 100%;
}
