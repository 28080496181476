/*
 *  Default CSS For Parcel Content Slider feature.
 *
 */



.view-content-slider {

  position: relative;

  .slick-slider {
    margin-bottom:0;
  }

  .slick-wrapper {
    margin-bottom: 15px;
    > div {
      @include clearfix;
    }
  }

  .slick-track {
    max-height:600px;
  }

  .slide__content {
    position: relative;
  }

  .slide-image {
    max-height:600px;
    overflow:hidden;
    img {
      min-width:100%;
      height:auto;
    }
  }

  .slide-text {
    position: relative;
    bottom:0;
    width:100%;
    background:rgba(0,0,0,0.3);
    background:darken($brand-primary,35%);
    color:#ffffff;
    padding:15px 30px;

    h2 {
      color:#ffffff;
      margin-bottom:0;
      font-size:2.5em;
    }

    .slide-description {
      p {
        margin-top:0;
        padding-top:10px;
        font-size:1.3em;
        font-weight:300;
      }
    }

  }

  //Bullets
  ul.slick-dots {
    position:absolute;
    bottom:0;

    li.slick-active {
      button:before {
        color:#ffffff;
        opacity:1;
      }
    }

    li {
      button {

        &:before {
        color:#ffffff;
        opacity:0.5;
        }
        &:hover {
          &:before {
            opacity:1;
          }
        }
      }
    }
  }

  .slick__arrow {
    button.slick-nav {
      &.slick-prev:before,
      &.slick-next:before {
        color:#ffffff;
      }
      &:hover {
        :before {
          color:#ffffff;
        }
      }
    }
  }

}

@media (min-width:$screen-sm) {
  .view-content-slider {

    position: relative;

    .slick-track {
    }

    .slide__content {
    }

    .slide-image {

      img {
      }
    }

    .slide-text {
      position: absolute;
      bottom:0;
      width:100%;
      background:rgba(0,0,0,0.3);
      color:#ffffff;
      padding:15px 30px;

      h2 {
      }

      .slide-description {
        p {
        }
      }

    }

    //Bullets
    ul.slick-dots {
      position:absolute;
      bottom:0;

      li.slick-active {
        button:before {
          color:#ffffff;
          opacity:1;
        }
      }

      li {
        button {

          &:before {
          color:#ffffff;
          opacity:0.5;
          }
          &:hover {
            &:before {
              opacity:1;
            }
          }
        }
      }
    }


  }
}
