// Styles for panels and panes.
// --------------------------------------------------


.container .container-fluid {
  margin-left: -15px;
  margin-right: -15px;
}

.container-fluid .container-fluid {
  margin-left: -15px;
  margin-right: -15px;
}