.pane-panopoly-news-articles-by-author {

  .views-row {
    &:before {
      display:none;
    }
  }

  .views-field-title {
    display:inline;
  }

}

.pane-panopoly-news-list-news-articles {

  ul.media-list {

    li.media {

      border-bottom:5px solid #f9f9f9;
      padding-bottom:1em;
      margin-bottom:0.75em;

    }

  }

}


.pane-panopoly-news-article-archive {

  ul.media-list {

    li.media {

      border-bottom:2px solid #f9f9f9;
      padding-bottom:0;
      margin-bottom:0.25em;

    }

  }

}

