// Styles for main layout
// --------------------------------------------------


//Remove 15px left and right padding on radix layouts used for panel panes.
// body.page-demo {
//   .radix-layouts-content > .panel-panel-inner {
//     @extend .row;
//   }
// }


.container .container-fluid .container-fluid {
width: auto;
margin-left: -15px;
margin-right: -15px;
}


.panel-pane {
  margin-bottom:15px;
}

.content > div > .container:first-of-type {
  margin-top:30px;
}

